<template>
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-2 pr-0">
        <label>Glosa:</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <textarea id="inputGlosa"
          v-model="glosa"
          class="form-control"
          placeholder="Ingrese alguna descripcion de la Cotización"
          rows="3">
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Glosa',
  data() {
    return {
    };
  },
  computed: {
    ...mapFields('glosa', [
      'glosa',
    ]),
  },
};
</script>

<style scoped>
</style>

<template>
  <b-modal id="modal-servicios"
    v-model="modalShow"
    size="lg"
    title="Agregar Servicio"
    @ok="agregarServicio"
    @hidden="resetearModal"
    @show="onOpenModal"
  >
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="form-group mb-0">
          <label class="col-form-label">Grupo de Servicio:</label>
          <multiselect id="comboCategoriaServicios"
            v-model="categoriaServiciosSelected"
            :options="categoriaServicios"
            @input="actualizarListaServicios"
            placeholder=""
            label="nombre" track-by="id"
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="!(categoriaServiciosSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
          </multiselect>
        </div>
      </div>
      <div class="col-sm-6" v-if="serviciosSelected.length < 2">
        <div class="form-group">
          <label class="col-form-label" style="user-select: none"><br></label>
          <div
            v-if="aseguradoraSelected !== null && listaPrecioSeguros.length !== 0"
            class="row mb-2"
          >
            <div class="col-sm-4 text-right pl-0">
              <label class="col-form-label">
                Precio del Seguro:
              </label>
            </div>
            <div class="col-sm-4 px-0">
              <multiselect id="comboPrecioSeguros"
                v-model="precioSeguroSelected"
                :options="listaPrecioSeguros"
                @input="actualizarInputPrecioSeguro"
                placeholder=""
                label="nombre" track-by="id"
                select-label=""
                deselect-label="X">
                <span slot="caret" v-if="!(precioSeguroSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="singleLabel" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
                <template slot="option" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
              </multiselect>
            </div>
            <div class="col-sm-4">
              <input type="text"
                class="form-control"
                :value="inputPrecioSeguro | numeral('0,0.00')"
                autocomplete="off"
                disabled
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="col-form-label">Servicio:</label>
          <multiselect id="comboServicios"
            ref="comboServicios"
            v-model="serviciosSelected"
            :options="listaServiciosInteligente"
            :multiple="true"
            :loading="loaderComboServicio"
            @select="agregarItemListaTemporal"
            @remove="eliminarItemListaTemporal"
            placeholder=""
            label="nombre" track-by="id"
            select-label=""
            deselect-label="X">
              <span slot="caret" v-if="!(serviciosSelected.length===0)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
          </multiselect>
        </div>
      </div>
      <div class="col-sm-6" v-if="serviciosSelected.length < 2">
        <div class="form-group">
          <label class="col-form-label" style="user-select: none"><br></label>
          <div class="row mb-2" v-if="precios.length !== 0 && precioSeguroSelected === null">
            <div class="col-sm-4 text-right">
              <label class="col-form-label">
                Lista de Precios:
              </label>
            </div>
            <div class="col-sm-4 px-0">
              <multiselect id="comboPrecios"
                v-model="precioSelected"
                :options="precios"
                @input="actualizarPrecioDelModal"
                :allow-empty="false"
                placeholder="Seleccione Precio"
                label="descuento" track-by="id"
                select-label=""
                deselect-label="X">
                <span slot="caret" v-if="!(precioSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
              </multiselect>
            </div>
            <div class="col-sm-4">
              <input id="inputPrecioSelectedFromCombo"
                type="text"
                class="form-control"
                :value="precioSelectedCombo | numeral('0,0.00')"
                autocomplete="off"
                disabled
              >
            </div>
          </div>
          <div class="row" v-if="precios.length !== 0">
            <div class="col-sm-4 offset-sm-4 px-0">
              <div class="form-group">
                <label class="label-control">Cantidad</label>
                <input type="text" id="inputCantidad"
                  ref="inputCantidad"
                  v-model="cantidad"
                  class="form-control"
                  autocomplete="off"
                  @keyup.enter="agregarServicio"
                  @keydown="validarCaracter"
                  @blur="reValidarCantidad">
              </div>
            </div>
            <div class="col-sm-4" v-if="precioSeguroSelected === null">
              <div class="form-group">
                <label class="label-control">Precio Manual</label>
                <vue-numeric class="form-control"
                  v-model="precioManual"
                  @input="actualizarPrecioModal"
                  @keyup.native.enter="agregarServicio"
                  separator=","
                  :precision="2"
                  placeholder=" "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="col-sm-6">
        <div class="form-group">
          <label class="col-form-label" style="user-select: none"><br></label>
          <div class="row">
            <div class="col-sm-2 text-right">
              <label class="col-form-label"><b>Total:</b></label>
            </div>
            <div class="col-sm-3 px-0">
              <input type="text"
                class="form-control"
                :value="getSubTotalComboServicios | numeral('0,0.00')"
                disabled
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <button class="btn btn-outline-dark" @click="cancel()">
        Cancelar
      </button>
      <button class="btn btn-warning" @click="ok()"
        :disabled="isDisabledAddButton">
        Agregar
      </button>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { util } from '@/plugins/util';

export default {
  name: 'ModalServicios',
  components: {
    Multiselect,
    VueNumeric,
  },
  data() {
    return {
      modalShow: false,
      loaderComboServicio: false,
      precioManual: 0,
    };
  },
  methods: {
    precioUnitario() {
      if (this.precioSeguroSelected !== null) {
        return parseFloat(this.inputPrecioSeguro).toFixed(2);
      }
      return parseFloat(this.precioSelectedCombo).toFixed(2);
    },
    precioTotal() {
      if (this.precioSeguroSelected !== null) {
        return this.cantidad * (
          parseFloat(this.inputPrecioSeguro) + parseFloat(this.precioSeguroSelected.seguro)
        );
      }
      return this.cantidad * parseFloat(this.precioSelectedCombo);
    },
    agregarServicio(bvModalEvt) {
      if (this.serviciosSelected.length === 0) {
        util.showNotify('Elije un Servicio', 'warn');
        bvModalEvt.preventDefault();
        return;
      }
      if (this.cantidad <= 0) {
        util.showNotify('Ingrese un Cantidad Mayor a 0!', 'warn');
        bvModalEvt.preventDefault();
        return;
      }
      this.precioSelectedCombo = this.precioSelectedCombo.toString().trim();
      if (this.precioSelectedCombo === '') {
        util.showNotify('Precio no Valido', 'warn');
        return;
      }
      if (this.listaDetalleTemporal.length === 1) {
        this.listaDetalleTemporal.forEach((servicioTemporal) => {
          let seguro = '0.00';
          if (this.precioSeguroSelected !== null) {
            seguro = this.precioSeguroSelected.seguro;
          }
          const item = {
            id: servicioTemporal.id,
            tempId: servicioTemporal.tempId,
            numero: servicioTemporal.numero,
            tipo_servicio_id: servicioTemporal.tipo_servicio_id,
            descripcion: servicioTemporal.descripcion,
            medicoTempSelected: null,
            cantidad: this.cantidad,
            precio_unitario: this.precioUnitario(),
            sub_total: this.precioTotal(),
            servicio: servicioTemporal.servicio,
            sub_total_seguro: seguro,
            estado: servicioTemporal.estado,
          };
          this.addItemListaServicios(item);
        });
      } else {
        this.listaDetalleTemporal.forEach((servicioTemp) => {
          this.addItemListaServicios(servicioTemp);
        });
      }
      this.listaTemporal = [];
      this.listaDetalleTemporal = [];
    },
    resetearModal() {
      this.resetModalWhenClosed();
      this.precioManual = 0;
    },
    onOpenModal() {
      this.obtenerServicios();
    },
    actualizarListaServicios() {
      this.servicios = [];
      this.precios = [];
      this.listaPrecioSeguros = [];
      if (this.categoriaServiciosSelected !== null) {
        this.obtenerServicios();
      } else {
        this.serviciosSelected = [];
        this.listaTemporal = [];
        this.listaDetalleTemporal = [];
      }
    },
    async agregarItemListaTemporal(el) {
      this.loaderComboServicio = true;
      this.setItemListaTemporal(el);
      await this.obtenerPreciosDelServicio();
      if (this.listaTemporal.length < 2) {
        await this.obtenerServicios();
      }
      this.loaderComboServicio = false;
      if (this.modalShow) {
        this.$refs.comboServicios.$el.focus();
      }
    },
    eliminarItemListaTemporal(el) {
      this.deleteItemListaTemporal(el.id);
      this.deleteItemListaDetalleTemporal(el.id);
      if (this.listaTemporal.length === 0) {
        this.precios = [];
        this.listaPrecioSeguros = [];
        if (this.listaServicios.length === 0) {
          this.setActividadEconomicaId(0);
          this.obtenerServicios();
        }
      }
      if (this.listaTemporal.length === 1) {
        this.deleteLastListaDetalleTemporal();
        this.obtenerPreciosDelServicio();
      }
      this.$refs.comboServicios.$el.focus();
    },
    actualizarPrecioInput() {
      this.precioSelectedCombo = this.precioSelected.monto;
    },
    actualizarPrecioDelModal() {
      this.actualizarPrecioInput();
      this.precioManual = 0;
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCantidad() {
      if (this.cantidad === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.cantidad)) {
        this.cantidad = '';
      }
    },
    actualizarPrecioModal() {
      if (this.precioManual === '') {
        this.obtenerPreciosDelServicio();
      } else {
        this.precioSelected = null;
        this.precioSelectedCombo = this.precioManual;
      }
    },
    actualizarInputPrecioSeguro() {
      if (this.precioSeguroSelected !== null) {
        this.inputPrecioSeguro = this.precioSeguroSelected.monto;
      } else {
        this.inputPrecioSeguro = 0;
      }
    },
    ...mapMutations('modalServicios', [
      'setActividadEconomicaId',
      'setItemListaTemporal',
      'deleteItemListaTemporal',
      'deleteItemListaDetalleTemporal',
      'deleteLastListaDetalleTemporal',
    ]),
    ...mapMutations('tablaServicios', [
      'addItemListaServicios',
    ]),
    ...mapActions('modalServicios', [
      'resetModalWhenClosed',
    ]),
    ...mapActions('cotizacionAPIS', [
      'obtenerServicios',
      'obtenerPreciosDelServicio',
    ]),
  },
  computed: {
    ...mapFields('formularioCotizacion', [
      'aseguradoraSelected',
    ]),
    ...mapFields('modalServicios', [
      'isDisabledAddButton',
      'categoriaServiciosSelected',
      'categoriaServicios',
      'serviciosSelected',
      'servicios',
      'precioSelected',
      'precios',
      'precioSelectedCombo',
      'cantidad',
      'listaTemporal',
      'listaDetalleTemporal',
      'precioSeguroSelected',
      'listaPrecioSeguros',
      'inputPrecioSeguro',
    ]),
    ...mapFields('tablaServicios', [
      'listaServicios',
    ]),
    ...mapGetters('modalServicios', [
      'getSubTotalComboServicios',
    ]),
    listaServiciosInteligente() {
      if (this.precioSeguroSelected !== null) {
        return [];
      }
      return this.servicios;
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

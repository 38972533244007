<template>
  <div class="row mb-1 border-bottom border-skyblue">
    <b-table
      class="table table-hover"
      :fields="fieldsListaServicios"
      :items="listaServicioFiltered"
    >
      <template #cell(servicio)="data">
        ({{data.item.numero}}) {{data.item.servicio}}
      </template>
      <template #cell(descripcion)="data">
        <input type="text"
          class="form-control"
          :value="data.item.descripcion"
          @input="actualizarDescripcionItem($event,data.index)">
      </template>
      <template #cell(coaseguro)="data">
        {{
          (
            (parseFloat(data.item.sub_total_seguro) > 0)?
            data.item.precio_unitario : 0) | numeral('0,0.00'
          )
        }}
      </template>
      <template #cell(precio_unitario)="data">
        {{
          (
            (parseFloat(data.item.sub_total_seguro) > 0)?
            0 : data.item.precio_unitario) | numeral('0,0.00'
          )
        }}
      </template>
      <template #cell(sub_total_seguro)="data">
        <span class="text-success font-weight-bold">
          {{data.item.sub_total_seguro | numeral('0,0.00')}}
        </span>
      </template>
      <template #cell(sub_total)="data">
        {{data.item.sub_total | numeral('0,0.00')}}
      </template>
      <template #cell(eliminar)="data">
        <i
           @click="eliminarItem(data)"
          class="nav-icon i-Close-Window text-danger font-weight-bold"
          style="cursor: pointer"
        >
        </i>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TablaServicios',
  data() {
    return {
      fieldsListaServicios: [
        {
          key: 'cantidad', label: 'Cantidad', thClass: 'th-custom-color', thStyle: { width: '7%' },
        },
        {
          key: 'servicio', label: 'Servicio', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '25%' },
        },
        {
          key: 'descripcion', label: 'Descripcion', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '26%' },
        },
        {
          key: 'coaseguro', label: 'CoaSeguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '7%' },
        },
        {
          key: 'sub_total_seguro', label: 'Seguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'precio_unitario', label: 'P/U', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'sub_total', label: 'Total', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
      ],
    };
  },
  methods: {
    actualizarDescripcionItem(e, indice) {
      this.listaServicios[indice].descripcion = e.target.value;
    },
    eliminarItem(data) {
      if (data.item.id === 0) {
        const INDEX = this.listaServicios.findIndex((el) => {
          const tempId = el?.tempId ?? 0;
          return tempId === data.item.tempId;
        });
        this.listaServicios.splice(INDEX, 1);
      } else {
        const INDEX = this.listaServicios.findIndex((el) => el.id === data.item.id);
        this.listaServicios[INDEX].estado = 0;
      }
      if (this.listaServicios.length === 0) {
        this.setActividadEconomicaId(0);
      }
    },
    ...mapMutations('addServiceModal', [
      'setActividadEconomicaId',
    ]),
  },
  computed: {
    ...mapFields('tablaServicios', [
      'listaServicios',
    ]),
    listaServicioFiltered() {
      return this.listaServicios.filter((el) => el.estado === 1);
    },
  },
};
</script>

<style scoped>
.border-skyblue {
  border-color: #61c6f2 !important;
}
</style>

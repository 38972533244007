<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-5">
        <div class="breadcrumb">
          <h1>Modificar Cotización
            <span class="h4 pl-2">
              N°: {{correlativo}}
            </span>
          </h1>
        </div>
      </div>
      <transition name="fade">
        <div v-if="showCortinaInformativa"
         class="col-sm-7 navbar-reubicado">
          <div class="row">
            <div
              v-if="parseFloat(pacienteToShow.tratamiento) !== 0
              && pacienteToShow.tratamiento !== null
              && parseFloat(pacienteToShow.anticipo) !== 0
              && pacienteToShow.anticipo !== null"
              class="col-sm-3 pt-2 pb-1 bg-yellow-custom
              text-center border-right border-white">
              Total Tratamiento: <br>
              {{pacienteToShow.tratamiento | numeral('0,0.00')}}
            </div>
            <div v-else class="col-sm-3"></div>
            <div
              v-if="parseFloat(pacienteToShow.anticipo) !== 0
              && pacienteToShow.anticipo !== null"
              class="col-sm-2 pt-2 pb-1 bg-yellow-custom text-center
              border-left border-right border-white">
              Tiene Anticipo: <br>
              {{pacienteToShow.anticipo | numeral('0,0.00')}}
            </div>
            <div v-else class="col-sm-2"></div>
            <div class="col-sm-3 py-2 bg-sky-light text-white border-left border-white">
              Edad: {{pacienteToShow.edad}} años
            </div>
            <div class="col-sm-4 py-2 bg-sky-light text-white">
              Nº HC: {{pacienteToShow.numero}}
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="col-sm-5">
              <button type="submit"
                @click="guardarFormulario"
                class="btn bg-custom-green mr-2 rounded"
                :disabled="buttonDisabled">
                Guardar
              </button>
              <router-link :to="{ name: 'cotizacion-index' }">
                <button
                  class="btn btn-white border border-light rounded">
                  Regresar
                </button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <!-- contiene formulario para la cotización -->
            <FormularioCotizacion />
            <!-- Tabla de los Servicios-->
            <TablaServicios />
            <div class="row py-1">
              <div class="col-sm-5">
                <div class="col-sm-12 mb-3">
                  <a
                    href="javascript:void(0)"
                    class="h5 text-skyblue"
                    @click="openModalServicios"
                  >
                    <b>Agregar Servicio</b>
                  </a>
                  <ModalServicios />
                </div>
                <Glosa />
              </div>
              <MontosFinales />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { util } from '@/plugins/util';
import FormularioCotizacion from '../components/TheFormularioCotizacion.vue';
import TablaServicios from '../components/TheTablaServicios.vue';
import ModalServicios from '../components/TheModalServicios.vue';
import Glosa from '../components/TheGlosa.vue';
import MontosFinales from '../components/TheMontosFinales.vue';

export default {
  name: 'edit',
  components: {
    FormularioCotizacion,
    TablaServicios,
    ModalServicios,
    Glosa,
    MontosFinales,
  },
  async created() {
    this.modoEdicion = true;
    await this.callEditApi(this.id);
    this.isLoading = false;
  },
  beforeDestroy() {
    this.resetInterfaz();
  },
  data() {
    return {
      isLoading: true,
      buttonDisabled: false,
      id: atob(this.$route.params.id),
    };
  },
  methods: {
    ...mapActions('cotizacionAPIS', [
      'callEditApi',
      'callUpdateApi',
    ]),
    ...mapActions('formularioCotizacion', [
      'resetFormularioCotizacion',
    ]),
    ...mapActions('tablaServicios', [
      'resetTablaServicios',
    ]),
    ...mapActions('modalServicios', [
      'resetModalServicios',
    ]),
    ...mapActions('glosa', [
      'resetGlosa',
    ]),
    resetInterfaz() {
      this.resetFormularioCotizacion();
      this.resetTablaServicios();
      this.resetModalServicios();
      this.resetGlosa();
    },
    redirect(path) {
      this.$router.push(path);
    },
    async guardarFormulario() {
      if (this.checkSeguroEstado && parseFloat(this.totalSeguro) <= 0) {
        util.showNotify('Ingrese un servicio con seguro!', 'warn');
        return;
      }
      this.buttonDisabled = true;
      const UPDATE_OK = await this.callUpdateApi(this.id);
      if (UPDATE_OK) {
        this.redirect({ name: 'cotizacion-index' });
      }
      this.buttonDisabled = false;
    },
    openModalServicios() {
      if (this.checkSeguroEstado && this.aseguradoraSelected === null) {
        util.showNotify('Elija un Seguro Medico.', 'warn');
        return;
      }
      this.$bvModal.show('modal-servicios');
    },
  },
  computed: {
    ...mapFields('formularioCotizacion', [
      'modoEdicion',
      'showCortinaInformativa',
      'pacienteToShow',
      'correlativo',
      'checkSeguroEstado',
      'aseguradoraSelected',
    ]),
    ...mapGetters('tablaServicios', [
      'totalSeguro',
    ]),
  },
};
</script>

<style scoped>
.btn-white{
  background: white;
  color: black;
}
.bg-yellow-custom {
  background-color: #f9d772;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(0px, -32px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.text-skyblue
{
  color: #61c6f2;
}
a.text-skyblue:hover{
  color:#1565c0;
}
</style>

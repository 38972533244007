<template>
  <div class="col-sm-3 offset-sm-4 pl-0">
    <div class="row mb-2">
      <div class="col-sm-6 text-right">
        <label class="col-form-label">
          <b>Sub Total:</b>
        </label>
      </div>
      <div class="col-sm-6 pl-0">
        <div class="div-input">{{subTotal | numeral('0,0.00')}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 text-right">
        <label class="col-form-label">
          <b>- Seguro:</b>
        </label>
      </div>
      <div class="col-sm-6 pl-0">
        <div class="div-input">{{totalSeguro | numeral('0,0.00')}}</div>
      </div>
    </div>
    <div class="row mb-2 th-custom-color py-2">
      <div class="col-sm-6 text-right">
        <h4 class="pt-1 text-dark">
          <b>Total:</b>
        </h4>
      </div>
      <div class="col-sm-6 pl-0">
        <div class="div-input bg-main">{{Total | numeral('0,0.00')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MontosFinales',
  computed: {
    ...mapGetters('tablaServicios', [
      'subTotal',
      'totalSeguro',
      'Total',
    ]),
  },
};
</script>

<style scoped>
.div-input {
  outline: initial !important;
  border: 1px solid #ced4da;
  color: #474d6d;
  background-color: rgb(238, 238, 238);
  opacity: 1;
  display: block;
  width: 100%;
  height: calc(1.9695rem + 2px);
  padding: .375rem .75rem;
  font-size: .813rem;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
</style>
